import React from 'react'
import Axios from 'axios'
import ReactRounded from "react-rounded-image"
import ReactStars from "react-rating-stars-component";
import {useState,useEffect} from 'react'

import './reviews.css';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
  } from 'reactstrap';
export const Reviews = () => {
    const [reviews,setreviews]=useState([]);
    useEffect(()=>{
      Axios.get("https://fiduciary---portal.herokuapp.com/read").then((response)=>{
        console.log(response);
      setreviews(response.data);
        });
    })
    
    const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === reviews.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? reviews.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }


    const slides=  reviews.map((val,key)=>{
        return(
            
       <CarouselItem className="review">
           
           <div className="r1">
           <div className="fl m-1 p-1">  
           <div> 
             <ReactRounded image={`https://fiduciary---portal.herokuapp.com/img/${val.dp}`} imageWidth="125" imageHeight="125" roundedSize="5" ></ReactRounded>
           {/* <img src={`http://localhost:3001/img/${val.dp}`} className="m-2" style={{height: "125px" , width: "125px", borderRadius: "50%"}}></img> */}
           </div>
          <div className="container"> 
          <h5>{val.name}</h5> 
           <ReactStars
         count={val.rating}
        
         size={25}
         color="#ffd700"
         />
         <p><strong className="text-dark">"</strong>{val.review}<strong className="text-dark">"</strong></p> 
         
        </div>
       </div>
       </div>
           </CarouselItem>
       
        );
      })

      if(reviews.length==0){
        return <div className="text-center mb-5">
          <h2>No Reviews</h2>
        </div>
      }
      else{
        return (
          <div>
              <div className="reviews text-center">
                  <h2>Reviews</h2>
              </div>
              <div className="r "> 
              <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators items={reviews} activeIndex={activeIndex} onClickHandler={goToIndex} />
        
        {slides}
        
        
        <CarouselControl className="arrow" direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
             </div> 
          </div>
      )
        
      }
    
}

