import "./services.css";
import riskImg from "../../assets/images/risk 1.jpg";
import mfund from "../../assets/images/mutual funds 1.jpg";
import retirement from "../../assets/images/retirement.jpg";
import loan from "../../assets/images/debt2.jpg";
import chiledu from "../../assets/images/childedu.jpg";
import tax from "../../assets/images/tax-new.jpg";
import wealth from "../../assets/images/wealthman.jpg";
import private2 from "../../assets/images/private2.jpg";

export default function Services() {
	return (
		<div>
			<div className="container pt-1">
				<div className="services-heading text-center">
					<h1>Our Services</h1>
				</div>

				<div className="row service-card" id="mutualFund">
					<div className="col-lg-6">
						<img
							className="img-thumbnail img-rounded"
							src={mfund}
							alt="Risk Management"
						/>
					</div>
					<div className="col-lg-6 right-text">
						<h2>Mutual Funds</h2>
						<p className="fs-5 lh-lg">
							Our Mutual Fund offerings are goal based depending on the Clients
							requirements and Financial Capacity. Our Mutual Fund offerings are
							from a diversified Scheme list of more than 800 schemes spread
							over 45 to 50 AMCs in India, offering a return of over 10%
							annually. We have a dedicated team handling Mutual funds and can
							give you detailed information on any of the Mutual Fund Schemes.
						</p>
					</div>
				</div>
				<div className="row service-card" id="retirementPlan">
					<div className="col-lg-6">
						<img
							className="img-thumbnail img-rounded"
							src={retirement}
							alt="Retirement Planning"
						/>
					</div>
					<div className="col-lg-6 right-text">
						<h2>Retirement Planning</h2>
						<p className="fs-5 lh-lg">
							In Retirement planning, we help our client determine their
							retirement income goals, and the actions and decisions necessary
							to achieve those goals. Retirement planning includes identifying
							sources of income, sizing up expenses, implementing a savings
							program, and managing assets and risk. We help you plan for your
							retirement with Guaranteed returns so you can live an independent
							life post retirement.
						</p>
					</div>
				</div>
				<div className="row service-card" id="riskMgmt">
					<div className="col-lg-6">
						<img
							className="img-thumbnail img-rounded"
							src={riskImg}
							alt="Risk Management"
						/>
					</div>
					<div className="col-lg-6 right-text">
						<h2>Risk Management</h2>
						<p className="fs-5 lh-lg">
							We help protect our clients from various risks by offering them
							appropriate risk covers like Health Mediclaim Cover to cover
							medical expenses in the event of hospitalisation, Life Insurance
							to protect their family in the event of unlikely events. We also
							offer general Insurance Cover like property Insurance, Car
							Insurance, Marine/Cargo Insurance and so on.
						</p>
					</div>
				</div>
				<div className="row service-card" id="childEdu">
					<div className="col-lg-6">
						<img
							className="img-thumbnail img-rounded"
							src={chiledu}
							alt="Children's Education / Marriage"
						/>
					</div>
					<div className="col-lg-6 right-text">
						<h2>Children's Education / Marriage</h2>
						<p className="fs-5 lh-lg">
							With the ever increasing cost of education and added to it the
							rising inflation of and average 5% annually, we give you
							guaranteed returns over a tenure of 8 to 20 years to meet your
							family goals like your children’s Higher education or in case you
							are planning to save for their marriage.
						</p>
					</div>
				</div>
				<div className="row service-card" id="loan">
					<div className="col-lg-6">
						<img className="im img-thumbnail img-rounded" src={loan} alt="Loans" />
					</div>
					<div className="col-lg-6 right-text ">
						<h2>Loans</h2>
						<p className="fs-5 lh-lg">
							We offer loans at very competitive market Interest Rates. In
							retail we have Home loans, Car Loans, and personal loans for
							Individual Clients. Corporate Loans or SME loans like Cash Credit,
							Overdraft Facilities, Term loans, Packing Credit in Foreign
							Currency, Foreign Currency Term Loan and so on. This helps our
							clients expand their business without worrying about day-to-day
							operational expenses. We have experienced ex bankers in our office
							with expertise knowledge.
						</p>
					</div>
				</div>
				<div className="row service-card" id="taxPlanning">
					<div className="col-lg-6">
						<img
							className="img-thumbnail img-rounded"
							src={tax}
							alt="Tax Planning"
						/>
					</div>
					<div className="col-lg-6 right-text">
						<h2>Tax Planning</h2>
						<p className="fs-5 lh-lg">
							In Tax planning we help our clients plan to ensure that all
							elements work together to allow them to pay the lowest taxes
							possible. With an efficient Tax Planning process in place like
							Short-Term and Long-term Tax Planning, Optimizing Savings for our
							Clients through Sections 80C and 1010D and 115. We believe Tax
							planning should be an essential part of an individual investor's
							financial plan.
						</p>
					</div>
				</div>
				<div className="row service-card" id="wealth">
					<div className="col-lg-6">
						<img className="img-thumbnail img-rounded" src={wealth} alt="wealthManagement" />
					</div>
					<div className="col-lg-6 right-text ">
						<h2>Wealth Management</h2>
						<p className="fs-5 lh-lg">
						We take a holistic approach within our company to meet the comlex needs of our clients across a broad range of services like accounting, retirement, estate planning, and tax services. Our clients have benefitted from this integrated approach thus creating a strategic plan for their current and future needs.
						</p>
					</div>
				</div>
				<div className="row service-card" id="privateEquity">
					<div className="col-lg-6">
						<img
							className="im1 img-thumbnail img-rounded"
							src={private2}
							alt="Private Equity"
						/>
					</div>
					<div className="col-lg-6 right-text">
						<h2>Private Equity</h2>
						<p className="fs-5 lh-lg">
						Considering the ever dynamic field of Private Finance and New Industries coming up with innovative solutions, we offer Private Finance Solutions to our clients for raising equity for their business. The Capital can be utilised to fund new technology, make acquisitions, expand working capital, and to bolster and solidify a Balance Sheet
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
