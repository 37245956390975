import logo from "../../assets/images/mainlogo3.jpg";
import "./navbar.css";

export default function Navbar() {
	return (
		<div>
			<header>
				<nav className="navbar fixed-top navbar-expand-lg navbar-light shadow-sm">
					<div className="container">
						<a className="navbar-brand" href="/" title="">
							<img
								src={logo}
								width="250"
								height="50"
								className="navbar-logo-img"
								alt=""
							/>
						</a>
						<button
							className="navbar-toggler"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarNav"
							aria-controls="navbarNav"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse" id="navbarNav">
							<ul className="navbar-nav ms-auto me-5 nav-links">
								<li>
									<a className="nav-link px-3" href="/services" title="">
										Services
									</a>
								</li>
								<li>
									<a className="nav-link px-3" href="/programs" title="">
										Other Programs
									</a>
								</li>
								<li>
									<a className="nav-link px-3" href="/about-us" title="">
										About Us
									</a>
								</li>
								<li>
									<a className="nav-link px-3" href="/contact-us" title="">
										Contact Us
									</a>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</header>
		</div>
	);
}
