import "./fidugrid.css";

import {
	debt,
	mfund,
	family,
	retirement,
	tax,
	risk,
	wealth,
	private1,
} from "../../assets/images";

export default function Grid() {
	return (
		<div className="container services-container mb-5">
			<div className="services">
				<h2>Our Exclusive Services</h2>
			</div>
			<div className="row flex-row justify-content-center mt-5">
				<a
					href="/services/#mutualFund"
					className="col-md-4 text-center service my-4"
				>
					<img src={mfund} height="80" width="80" alt="Mutual Funds" />
					<br />
					Mutual Funds
				</a>
				<a
					href="/services/#retirementPlan"
					className="col-md-4 text-center service my-4"
				>
					<img
						src={retirement}
						height="80"
						width="80"
						alt="Retirement Planning"
					/>
					<br />
					Retirement Planning
				</a>
				<a
					href="/services/#riskMgmt"
					className="col-md-4 text-center service my-4"
				>
					<img src={risk} height="80" width="80" alt="Risk Management" />
					<br />
					Risk Management
				</a>
				<a
					href="/services/#childEdu"
					className="col-md-4 text-center service my-4"
				>
					<img
						src={family}
						height="80"
						width="80"
						alt="Children's Marriage/Education"
					/>
					<br />
					Children’s education/marriage
				</a>
				<a href="/services/#loan" className="col-md-4 text-center service my-4">
					<img src={debt} height="80" width="80" alt="Debt Instruments" />
					<br />
					Debt Instruments - Loans
				</a>
				<a
					href="/services/#taxPlanning"
					className="col-md-4 text-center service my-4"
				>
					<img src={tax} height="80" width="80" alt="Tax Planning" />
					<br />
					Tax Planning
				</a>
				<a
					href="/services/#wealth"
					className="col-md-4 text-center service my-4"
				>
					<img src={wealth} height="80" width="80" alt="Wealth Management" />
					<br />
					Wealth Management
				</a>
				<a
					href="/services/#privateEquity"
					className="col-md-4 text-center service my-4"
				>
					<img src={private1} height="80" width="80" alt="Private Equity" />
					<br />
					Private Equity
				</a>
			</div>
		</div>
	);
}
