import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./pages/main/main";
import Services from "./pages/services/services";
import Programs from "./pages/programs/programs";
import Contact from "./pages/contactus/contact";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import {About} from "./pages/aboutus/about";
import "./App.css";

function App() {
	return (
		<Router>
			<Navbar />
			<Switch>
				<Route exact path="/services">
					<Services />
				</Route>
				<Route exact path="/programs">
					<Programs />
				</Route>
				<Route exact path="/about-us">
					<About />
				</Route>
				<Route exact path="/contact-us">
					<Contact />
				</Route>
				<Route path="/">
					<Main />
				</Route>
			</Switch>
			<Footer />
		</Router>
	);
}

export default App;
