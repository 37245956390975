import React from "react";
import "./main.css";
import mainImg from "../../assets/images/main.jpg";
import Grid from "../../components/grid/fidugrid.js";
import {Reviews} from "../../components/reviews/reviews.js"
import {Query} from "../../components/query/Query"

export default function Main() {
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(e.currentTarget[0].value);
		window.Email.send({
			Host: "smtp.gmail.com",
			Username: "fiduciary.capital111@gmail.com",
			Password: "fiduciary@123",
			To: "capfidu@gmail.com",
			From: "fiduciary.capital111@gmail.com",
			Subject: "Email Address from Fiduciary Capital",
			Body: e.currentTarget[0].value,
		}).then((message) => alert(message));
		e.currentTarget[0].value="";
	};

	return (
		<div>
			<div
				className="container-fluid main-img"
				style={{ backgroundImage: `url(${mainImg})`, backgroundSize: "cover" }}
			>
				<div className="container h-100">
					<div className="row h-100">
						<div className="col-12-lg d-flex flex-column img-text justify-content-center h-100">
							<div className="text-center main-top-text mb-5 fs-3">
								<h1>YOUR FINANCE PARTNER</h1>
								<h1>WHO LOOKS AFTER YOUR WELL BEING.</h1>
							</div>
							<div className="text-center text-white fs-4 p-2">
								<p>
									Fiduciary Capital is a Financial Services and Advisory Company
									established and based in Mumbai since 2020. <br /> We are
									dedicated to customise Solutions for our clients after
									understanding their Financial requirements over long
									term/short term.{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid mb-5 pt-5 pb-4 email-form">
				<div className="container text-center">
					<h4>
						Drop your email address over here and we will get back to you with
						free consultation.
					</h4>
					<form
						className="input-group my-4 w-50 text-center mx-auto"
						onSubmit={(e) => handleSubmit(e)}
					>
						<input
							type="email"
							className="form-control"
							placeholder="Email Address"
							name="email"
							aria-label="email"
							aria-describedby="button-addon2"
						/>
						<button
							className="btn btn-secondary"
							type="submit"
							id="button-addon2"
						>
							Send
						</button>
					</form>
				</div>
			</div>

			<div className="container objective-container">
				<div className="row">
					<div className="col-md-18">
						<div className="text-center intro-text-1">
							<h2
								className="fs-1"
								style={{ color: "rgb(1, 116, 197)", fontWeight: "600" }}
							>
								What do we do
							</h2>
						</div>
						<div className="text-center">
							{/* <!-- <h2>Vivamus laoreet</h2> --> */}
							<p className=" fs-4 text-black-50 mt-4">
								{" "}
								Our team here caters to the need based Financial Services of our
								clients. Our various productofferings include Investment
								Solutions, Loans, Risk Cover and Tax Planning.We have been
								successfully serving our clients over the past couple of years
								with the utmost Customer Satisfaction and Clients needs at the
								forefront.
							</p>
						</div>
					</div>
					<div className="text-center intro-text-2">
						<h2
							className=" fs-1"
							style={{ color: "rgb(1, 116, 197)", fontWeight: "600" }}
						>
							Our Objective
						</h2>
					</div>

					<div className="text-center">
						<p className="fs-4 text-black-50 mt-4">
							To Carry on our Business as Financial Advisors and to advise and
							assist our Clientele over a diversified set of Financial Services
							like Investment Solutions, Loans, Risk Cover, Tax Planning and
							Government Bonds.
						</p>
					</div>
				</div>
			</div>

			<Grid />
			<Reviews />
			<Query />
		</div>
	);
}
