import "./about.css";
import maam2 from "../../assets/images/maam2.jpg";

import React from 'react'

export const About = () => {
    return (
        <div>
            <div className="text-center program-heading">
					<h1>About Us </h1>
		</div>
        <div className="row service-card" >
					<div className="col-lg-6 m12">
						<img
							className="mimg"
							src={maam2}
							alt="Aishwarya Mohol"
						/>
                        <h2 className="name mt-3">Ms. Aishwarya Ravindra Mohol</h2>
					</div>
					<div className="col-lg-6 right-text1">
						<p className="fs-5 lh-lg program-text">
                        Fiduciary Capital was founded in 2020 with the intention of providing professional Financial Services to our Clients in the various areas of Finance. It was founded by Ms. Aishwarya Ravindra Mohol. She holds a Bachelor of Engineering degree from Mumbai University and is a post Graduate Finance from Symbiosis International University.
She has worked in the Banking Industry for 5 years. Her first stint was with Axis Bank in the Treasury department where she handled multiple Forex deals like Capital Account Transactions (FDI, ODI, etc.)and Current Account Transactions (inwards and outwards deals). She later went on to handle the Forex deals in IndusInd bank handling big Corporates. She now runs a Financial Consultancy of hers and spearheads various products like Risk Management, Taxation Planning, Loans, Fund Raising, Private Equity and so on. You can get in touch with her on
						</p>
                        <p className="fs-5 an"><strong>Email:</strong> <a href="mailto:cfiduciary@gmail.com">cfiduciary@gmail.com</a></p>
                        <p className="fs-5 an"><strong>Mob No: </strong><a href="tel: <+91 9923229632>">+91 9923229632</a></p>
					</div>
				</div>
        </div>
    )
}
