export const debt = require("./vectors/debt.png").default;
export const mfund = require("./vectors/mfund.png").default;
export const retirement = require("./vectors/retirement.png").default;
export const family = require("./vectors/family.png").default;
export const risk = require("./vectors/risk.png").default;
export const tax = require("./vectors/tax.png").default;
export const whatsapp = require("./whatsapp.png").default;
export const facebook = require("./facebook.png").default;
export const gmail = require("./gmail.png").default;
export const call = require("./call.png").default;
export const wealth = require("./vectors/wealth.png").default;
export const private1 = require("./vectors/private.png").default;
