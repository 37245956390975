import React,{useState} from 'react'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import './Query.css'
export const Query = () => {
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [mob,setMob] = useState("");
    const [query,setQuery] = useState("");

    const Submit = (e) => {
		e.preventDefault();
		console.log(e.currentTarget[0].value);
		window.Email.send({
			Host: "smtp.gmail.com",
			Username: "fiduciary.capital111@gmail.com",
			Password: "fiduciary@123",
			To: "capfidu@gmail.com",
			From: "fiduciary.capital111@gmail.com",
			Subject: "Queries of user",
			Body: `Name : ${name} 
                    Email id: ${email}
                    Mobile no : ${mob}
                    Query : ${query}`,
		}).then((message) => alert(message));
        setEmail("");
        setMob("");
        setName("");
        setQuery("");
	};

    return (
        <div class="container f4   mb-4 p-4" >
            <div className="query text-center">
                <h2>Contact for Additional Information</h2>
            </div>
            <form onSubmit={(e) => Submit(e)}>
            <div class="form-group mt-1">
    <label for="name">Name</label>
    <input type="text" class="form-control mb-2" id="name" name="name" required value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Enter your name" />
  </div>

  <div class="form-group">
    <label for="exampleInputEmail1">Email address</label>
    <input type="email" class="form-control" name="email" required value={email} id="email" onChange={(e)=>{setEmail(e.target.value)}} aria-describedby="emailHelp" placeholder="Enter email" />
    <small id="emailHelp" class="form-text text-muted mb-2">We'll never share your email with anyone.</small>
  </div>
  <div class="form-group">
    <label for="mob">Mobile number</label>
    <PhoneInput
      placeholder="Enter your mobile number"
      value={mob}
      onChange={setMob}/>
  </div>
  <div class="form-group mt-1">
    <label for="query">Enter your Query</label>
    <textarea type="text" class="form-control mb-2 " name="query" required value={query} onChange={(e)=>{setQuery(e.target.value)}} placeholder="Enter your Query" id="query" />
  </div>
  
  <button type="submit"  class="btn btn-primary m-3">Submit</button>
</form>
        </div>
    )
}
