import "./footer.css";
import logopng from "../../assets/images/logopng2.png";

export default function Footer() {
	return (
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-md-8 mb-4">
						<img
							src={logopng}
							alt="Fiduciary Capital"
							height="70"
							width="300"
						/>
					</div>

					<div className="col-md-4">
						<h4>Follow Us</h4>
						<div className="d-flex">
							<a
								href="https://www.facebook.com/Fiduciary-Capital-110695591068108"
								className="social-round-icon rounded-circle fa-icon d-block align-center"
								title=""
							>
								<i
									className="fab fa-facebook-f w-100 align-bottom text-center"
									aria-hidden="true"
								></i>
							</a>
							
							<a
								href="https://www.linkedin.com/in/fiduciary-capital-653b1a217"
								className="social-round-icon rounded-circle fa-icon d-block"
								title=""
							>
								<i
									className="fab fa-linkedin-in w-100 align-bottom text-center"
									aria-hidden="true"
								></i>
							</a>
						</div>
						<p style={{ marginTop: "1em", color: "white" }}>
							<small>© Fiduciary Capital 2021</small>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
}
