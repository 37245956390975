import "./programs.css";
import seminar1 from "../../assets/images/seminar1.jpg";
import seminar2 from "../../assets/images/seminar2.jpg";

export default function Programs() {
	return (
		<div className="container-fluid my-5">
			<div className="container">
				<div className="text-center program-heading">
					<h1>Financial Awareness Programs </h1>
				</div>
				<div
					id="carouselExampleControls"
					class="carousel slide"
					data-bs-ride="carousel"
				>
					<div class="carousel-inner">
						<div class="carousel-item active">
							<img
								src={seminar1}
								class="d-block w-75 mx-auto slide-img"
								alt="..."
							/>
						</div>
						<div class="carousel-item">
							<img
								src={seminar2}
								class="d-block w-75 mx-auto slide-img"
								alt="..."
							/>
						</div>
						{/* <div class="carousel-item">
							<img src="..." class="d-block w-100" alt="..." />
						</div> */}
					</div>
					<button
						class="carousel-control-prev"
						type="button"
						data-bs-target="#carouselExampleControls"
						data-bs-slide="prev"
					>
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Previous</span>
					</button>
					<button
						class="carousel-control-next"
						type="button"
						data-bs-target="#carouselExampleControls"
						data-bs-slide="next"
					>
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Next</span>
					</button>
				</div>

				<div className="text-center px-md-5 mt-5 mb-4 program-text">
					<p className="fs-4 text-black-50 mt-4">
						We at Fiduciary Capital understand that in today’s fast paced
						dynamic world, It is important for our clients to be updated about
						the latest Financial Information and scope to Maximize their
						returns. Hence we conduct Seminars and Financial Awareness Programs
						in corporates, colleges and schools. We cover topics like
						diversification of the portfolio, need based client investments,
						portfolio planning and so on. Many of our clients have benefitted
						through these awareness programs and we have helped them in
						generating better returns.
					</p>
				</div>
			</div>
		</div>
	);
}
