import "./contact.css";
import { facebook, gmail, whatsapp, call } from "../../assets/images";

export default function Contact() {
	return (
		<div className="background">
			<div class="container container-contact align-items-center h-100">
				<div class="row w-100 icons mb-2">
					<div className="col-3 text-center">
						<a href="mailto:cfiduciary@gmail.com">
							<img src={gmail} alt="Mail" width="90" height="90" />
						</a>
					</div>
					<div className="col-3 text-center">
						<a href="https://www.facebook.com/Fiduciary-Capital-110695591068108">
							<img src={facebook} alt="Facebook" width="90" height="90" />
						</a>
					</div>
					<div className="col-3 text-center">
						<a href="https://wa.me/<+91 9923229632>">
							<img src={whatsapp} alt="Whatsapp" width="90" height="90" />
						</a>
					</div>
					<div className="col-3 text-center">
						<a href="tel: <+91 9923229632>">
							<img src={call} alt="Call" width="90" height="90" />
						</a>
					</div>
				</div>
				<div class="row mt-4">
					<div class="text-center col-12">
						<h1 className="text-background my-4 mx-auto">
							<strong>Feel Free to get in touch</strong>
						</h1>
						<h1
							className="text-background my-3 mx-auto"
							style={{ color: "white" }}
						>
							<strong>OR</strong>
						</h1>
						<h1 className="text-background my-4 mx-auto">
							<strong>Visit us at :- </strong>
						</h1>
					</div>
				</div>

				<div class="row w-100">
					<div className="col-md-8 google-map order-md-1 order-2">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d332.71839767450473!2d72.81566360631179!3d19.357070005616574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7af4b9389cad1%3A0xa8f4c2d873f1a434!2sFiduciary%20Capital!5e0!3m2!1sen!2sin!4v1626773014468!5m2!1sen!2sin"
							width="100%"
							height="100%"
							loading="lazy"
							title="Map Location"
						></iframe>
					</div>
					<div class="h-100 col-md-4 order-md-2 order-1">
						<div class="bg-light address m-4 p-4 rounded">
							<h2>Our Office</h2>
							<p>
								Plot No. 23, Above Mohol Hyundai, Papdy Industrial Estate, Vasai
								(W) - 401207
							</p>
							<p>
								<span>Mobile Number:</span>+91 9637263446
							</p>
							<p>
								<span>Email:</span> cfiduciary@gmail.com
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
